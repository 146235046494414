<template>
  <div>
    <!-- System table -->
    <div class="card"
         v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], activity)"
         qid="systems-section"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <!--<h5 class="float-left" qid="systems-table-title">-->
              <!--{{ $t('projects.observations.title') }}-->
            <!--</h5>-->
          </div>
          <div class="col-sm-6 text-right">
            <CreateObservationModal
              v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
              :object="activity"
              @updated="loadObservations"
            ></CreateObservationModal>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('table.name') }}
            </th>
            <th scope="col">
              {{ $t('table.scope') }}
            </th>
            <th scope="col">
              {{ $t('table.impact') }}
            </th>
            <th width="100" class="text-center">
              {{ $t('table.attachment') }}
            </th>
            <th width="180"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in sTableItems">
              <td>
                <button v-if="object"
                        @click="openViewModal(object)"
                        qid="observation-name-link"
                        class="btn btn-link text-left"
                >
                  <font-awesome-icon size="lg" icon="binoculars" class="mr-1" />
                  {{ object.name }}
                </button>
              </td>
              <td>
                <span v-if="object && object.scope">
                  <font-awesome-icon icon="desktop" size="lg" class="mr-2" v-if="object.scope.who_am_i == 'App\\System\\Models\\System'"/>
                  <font-awesome-icon icon="cubes" size="lg" class="mr-2" v-if="object.scope.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                  <font-awesome-icon icon="cube" size="lg" class="mr-2" v-if="object.scope.who_am_i == 'App\\Process\\Models\\Process'"/>
                  <font-awesome-icon icon="file-signature" size="lg" class="mr-2" v-if="object.scope.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                  {{ object.scope.name }}
                </span>
              </td>
              <td>
                <span class="m-2" v-if="object.impact_assessment == 'Very low'">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      :class="'risk-1'"
                  />
                  {{ $t('risk.very_low') }}
                </span>
                <span class="m-2" v-if="object.impact_assessment == 'Low'">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      :class="'risk-3'"
                  />
                  {{ $t('risk.low') }}
                </span>
                <span class="m-2" v-if="object.impact_assessment == 'High'">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      :class="'risk-9'"
                  />
                  {{ $t('risk.high') }}
                </span>
                <span class="m-2" v-if="object.impact_assessment == 'Very high'">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      :class="'risk-16'"
                  />
                  {{ $t('risk.very_high') }}
                </span>
              </td>
              <td class="text-center">
                <button v-if="(object.media && object.media.length) || (object.links && object.links.length)"
                        @click="openViewModal(object)"
                        qid="observation-name-link"
                        class="btn btn-link"
                >
                  <font-awesome-icon icon="paperclip"/>
                </button>
              </td>
              <td class="text-right">

                <button v-if="object"
                        @click="openViewModal(object)"
                        qid="observation-name-link"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>

                <b-button class="btn btn-edit"
                          variant="edit"
                          @click="showEditModal(object)"
                          qid="delete-s-cancel-button"
                          v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                >
                  <span>
                    <font-awesome-icon icon="pen"/>
                  </span>
                </b-button>
                <b-button class="btn btn-delete"
                        variant="delete"
                        @click="showSConfirmDeleteModal(object)"
                        v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </td>
            </tr>
            <tr v-if="!sLoading && !sTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>


    <!-- Delete observation modal template -->
    <b-modal ref="delete-observation" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-observation-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-observation-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-observation-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>





    <!-- Edit observation modal -->
    <b-modal ref="edit-observation" hide-footer @hide="hideEditModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('projects.observations.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group v-if="activity && activity.type != 'control_assessment'">
            <label qid="create-s-tags-label">
              {{ $t('projects.observations.create.scope_label') }}
            </label>
            <multiselect
                v-model="selectedScope"
                :options="scopeItems"
                :placeholder="$t('projects.observations.create.scope_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="confidentiality-form-impact"
            ></multiselect>
          </b-form-group>
          <b-form-group v-if="activity && activity.type == 'control_assessment'">
            <label qid="create-s-tags-label">
              {{ $t('projects.observations.create.task_label') }}
            </label>
            <multiselect
                v-model="selectedTask"
                :options="tasks"
                :placeholder="$t('projects.observations.create.task_placeholder')"
                label="title"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="confidentiality-form-impact"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-v-name-label">
              {{ $t('projects.observations.create.name_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-input
                qid="create-v-form-name"
                type="text"
                v-model="editForm.name"
                required
                :maxLength="255"
                :placeholder="$t('projects.observations.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-v-description-label">
              {{ $t('projects.observations.create.description_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                qid="create-v-form-description"
                v-model="editForm.description"
                rows="6"
                max-rows="6"
                required
                :placeholder="$t('projects.observations.create.description_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <label qid="create-s-tags-label">
              {{ $t('projects.observations.create.impact_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                v-model="selectedImpact"
                :options="impactItems"
                :placeholder="$t('projects.observations.create.impact_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="confidentiality-form-impact"
            ></multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <ObservationViewModal
        :object="selectedObservation"
        :project="activity"
        :showModal="viewObservationModal"
        @closeViewModal="handleCloseViewModal"
    ></ObservationViewModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ObservationViewModal from '@/views/Projects/ObservationViewModal.vue';
  import CreateObservationModal from '@/views/Projects/CreateObservationModal.vue';

  export default {
    name: 'ObservationsTable',
    data: function() {
      return {
        form: {
          name: '',
          description: '',
          links: []
        },
        editForm: {
          name: '',
          description: '',
          scope_id: '',
          impact_assessment: ''
        },

        activity: {},
        sTableItems: [],
        selectedS: [],
        viewSModal: false,
        sLoading: true,

        DOItems: [],
        tasks: [],
        selectedTask: [],

        error: '',
        itemToDelete: {},
        buttonDisabled: false,

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',

        selectedObservation: {},
        viewObservationModal: false,

        selectedFiles: [],
        inputLink: '',
        inputLinkName: '',
        showDocuments: false,
        impactItems: [
          { name: this.$t('impact.very_low'), value: 'Very low' },
          { name: this.$t('impact.low'), value: 'Low' },
          { name: this.$t('impact.high'), value: 'High' },
          { name: this.$t('impact.very_high'), value: 'Very high' }
        ],
        selectedImpact: [],
        scopeItems: [],
        selectedScope: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      ObservationViewModal,
      CreateObservationModal
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      getFiles() {
        return this.selectedFiles
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value;
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadDO(this.keywordValue)
      }, 400),
      resetFile() {
        let imagefile = document.querySelector('#file-p');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file-p');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.form.links.push({url: this.inputLink, name: this.inputLinkName})
          // Reset link input form
          this.inputLink = ''
          this.inputLinkName = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.form.links.splice(index, 1);
      },
      showEditModal(object) {
        let self = this;
        this.error = '';
        this.loadTasks()
        this.selectedS = object

        this.editForm.name = object.name
        this.editForm.description = object.description

        if (this.activity.type != 'control_assessment') {
          this.selectedScope = []
          if (object.scope && object.scope.id) {
            this.selectedScope = _.find(this.scopeItems, function(item) {
              return item.id === object.scope.id;
            });
          }
        } else {
          this.selectedTask = []
          if (object.scope && object.scope.id) {
            this.selectedTask = _.find(this.tasks, function(item) {
              return item.id === object.scope.id;
            });
          }
        }

        this.selectedImpact = []
        if (object.impact_assessment) {
          this.selectedImpact = _.find(this.impactItems, function(item) {
            return item.value === object.impact_assessment;
          });
        }

        this.$refs['edit-observation'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.$refs['edit-observation'].hide()
      },
      showCreateModal() {
        this.selectedImpact = []
        this.selectedScope = []
        this.form.name = ''
        this.form.description = ''
        this.loadTasks()

        this.$refs['create-observation'].show()
      },
      hideCreateModal() {
        this.error = ''
        this.form.links = [];
        this.selectedFiles = [];
        this.$refs['create-observation'].hide()
      },
      showSConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-observation'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-observation'].hide()
      },
      handleCloseViewModal () {
        this.loadObservations(this.currentPage);
        this.viewObservationModal = false;
        this.selectedObservation = []
      },
      openViewModal(object) {
        this.selectedObservation = object
        this.viewObservationModal = true
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('projects/' + this.$route.params.slug + '/observations/' + object.id).then(function() {
          self.hideSConfirmDeleteModal();
          self.loadObservations();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('projects.observations.delete.success'))
          self.$emit('updated');
        }, function() {});
      },
      loadTasks() {
        let self = this;
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.tasks = response.data.items;
        }, function() {});
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        let files = document.querySelector('#file-p');
        let sizeError = false;

        // Call handle link function to gather link from link input
        this.handleLink()

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of attachments to FormData
        for (var i = 0; i < files.files.length; i++) {
          // Equal to 10 MB
          if (files.files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append("files[]", files.files[i]);
        }

        // Append list of links to FormData
        for (var i = 0; i < this.form.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.form.links[i]));
        }

        if (this.selectedImpact && this.selectedImpact.value) {
          formData.append("impact_assessment", this.selectedImpact.value);
        }

        if (this.selectedScope && this.selectedScope.id) {
          formData.append("scope_id", this.selectedScope.id);
        }

        formData.append("name", this.form.name);
        formData.append("description", this.form.description);

        if (self.activity.scope && self.activity.scope == 'process') {
          formData.append("scope_type", 'App\\Process\\Models\\Process');
        }
        if (self.activity.scope && self.activity.scope == 'system') {
          formData.append("scope_type", 'App\\System\\Models\\System');
        }
        if (self.activity.scope && self.activity.scope == 'activity') {
          formData.append("scope_type", 'App\\Activity\\Models\\Activity');
        }
        if (self.activity.scope && self.activity.scope == 'third_party') {
          formData.append("scope_type", 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty');
        }


        formData.append("client", this.getClient.slug);

        if (sizeError) {
          this.error = this.$t('system.file_size')
          this.buttonDisabled = false;
        } else {
          if (this.selectedImpact && this.selectedImpact.value && this.selectedScope && this.selectedScope.id) {
            piincHttp.post('/projects/' + this.$route.params.slug + '/observations', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.$toastr('success', self.$t('projects.observations.create.success'))
              self.hideCreateModal();
              self.loadObservations();
              self.buttonDisabled = false;
              self.$emit('updated');
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            this.error = '';
            self.buttonDisabled = false;
            if (!this.selectedScope || (this.selectedScope && !this.selectedScope.id)) {
              this.error += this.$t('projects.observations.create.scope_error_message')
            }
            if (!this.selectedImpact || (this.selectedImpact && !this.selectedImpact.value)) {
              this.error += ' ' + this.$t('projects.observations.create.impact_error_message')
            }
          }
        }
      },
      editFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.editForm.impact_assessment = this.selectedImpact ? this.selectedImpact.value : '';

        if (this.activity && this.activity.type == 'control_assessment') {
          if (this.selectedTask && this.selectedTask.id) {
            this.editForm.scope_id = this.selectedTask ? this.selectedTask.id : '';
          }
        } else {
          this.editForm.scope_id = this.selectedScope ? this.selectedScope.id : '';
        }

        if (this.selectedImpact && this.selectedImpact.value) {
          piincHttp.put('projects/' + this.$route.params.slug + '/observations/' + this.selectedS.id, this.editForm).then(function(response) {
            self.hideEditModal();
            self.loadObservations();
            // Emit event to parent component to update newest validation
            self.$emit('reloadContent', response.data);
            self.$toastr('success', self.$t('projects.observations.update.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors.users) {
                self.error += ' ' + error.data.errors.users[0]
              }
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (!this.selectedScope || (this.selectedScope && !this.selectedScope.id)) {
            this.error += this.$t('projects.observations.create.scope_error_message')
          }
          if (!this.selectedImpact || (this.selectedImpact && !this.selectedImpact.value)) {
            this.error += ' ' + this.$t('projects.observations.create.impact_error_message')
          }
        }
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadObservations(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadObservations(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadObservations(1, self.selectedPerPage)

      },
      loadObservations(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        let self = this;
        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;

        piincHttp.get('projects/' + this.$route.params.slug + '/observations', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data && response.data.items) {
            self.sTableItems = response.data.items;
          }
          // Set number of total items
          self.totalRows = response.data.total_count;
          self.sLoading = false;
        }, function() {});
      },
      loadScopeItems() {
        let self = this;
        self.scopeItems = []
        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {

          if (self.activity.scope && self.activity.scope == 'process') {
            _.forEach(response.data.items, function (item) {
              if (item.model && item.model.who_am_i == 'App\\Process\\Models\\Process') {
                self.scopeItems.push(item.model)
              }
            })
          }
          if (self.activity.scope && self.activity.scope == 'system') {
            _.forEach(response.data.items, function (item) {
              if (item.model && item.model.who_am_i == 'App\\System\\Models\\System') {
                self.scopeItems.push(item.model)
              }
            })
          }
          if (self.activity.scope && self.activity.scope == 'activity') {
            _.forEach(response.data.items, function (item) {
              if (item.model && item.model.who_am_i == 'App\\Activity\\Models\\Activity') {
                self.scopeItems.push(item.model)
              }
            })
          }
          if (self.activity.scope && self.activity.scope == 'third_party') {
            _.forEach(response.data.items, function (item) {
              if (item.model && item.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
                self.scopeItems.push(item.model)
              }
            })
          }

          self.sLoading = false;
        }, function() {});
      },
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {
      }
      // Table items
      this.loadObservations();
      this.loadScopeItems()
      this.loadTasks()

    }
  }
</script>
